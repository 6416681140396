import React from 'react'
import { useSiteQuery } from '../../fragments/hooks/useSiteQuery'
import * as styles from './signupForm.module.scss'
const SignupForm = () => {
    const { emailAddress,
        firstName,
        lastName,
        zipCode,
        dateOfBirth } = useSiteQuery()

    return (
        <div className='container container-lg'>
            <div className={styles.form}>
                <form>
                    <div className={styles.emailBox} data-required-error-message="Please enter your E-mail Address." data-invalid-value-error-message="Please enter a valid E-mail Address.">
                        <div className="control-group">
                            <label className={styles.formLabel} htmlFor="email"> {emailAddress} <span className="required">*</span> </label>
                            <div className="controls">
                                <input className={styles.emailInput} type="text" title="Email Address" name="email" id="email" placeholder={emailAddress} required pattern="^$|^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)*(\.[a-zA-Z]{2,})$" spellCheck="false" data-ms-editor="true" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <div className={`${styles.firstBox} ${styles.padRight}`}>
                            <label className={styles.formLabel} htmlFor='givenName'>{firstName} <span>*</span></label>
                            <div>
                                <input className={styles.emailInput} type="text" title={firstName} name="givenName" id="givenName" placeholder={firstName} required pattern="^[a-zA-ZÀ-ž\-'()\.]{1}[\sa-zA-ZÀ-ž\-'()\.]{1,}$" spellCheck="false" data-ms-editor="true" />
                            </div>
                        </div>
                        <div className={`${styles.lastBox} ${styles.padLeft}`}>
                            <label className={styles.formLabel} htmlFor='familyName'>{lastName} <span>*</span></label>
                            <div>
                                <input className={styles.emailInput} type="text" title={lastName} name="familyName" id="familyName" placeholder={lastName} required pattern="^[a-zA-ZÀ-ž\-'()\.]{1}[\sa-zA-ZÀ-ž\-'()\.]{1,}$" spellCheck="false" data-ms-editor="true" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <div className={`${styles.firstBox} ${styles.padRight}`}>
                            <label className={styles.formLabel} htmlFor='postalcode'> {zipCode} <span>*</span></label>
                            <div>
                                <input className={styles.emailInput} type="text" title="Zip Code" name="postalCode" id="postalCode" placeholder="Zip Code" required pattern="^$|^\d{5}(-\d{4})?$" spellCheck="false" data-ms-editor="true" />
                            </div>
                        </div>
                        <div className={`${styles.lastBox} ${styles.padLeft}`}>
                            <label className={styles.formLabel} htmlFor='birthday'> {dateOfBirth} <span>*</span></label>
                            <div>
                                <input className={styles.emailInput} type="date" title={dateOfBirth} id="birthday" name="birthday" placeholder={dateOfBirth} aria-label={dateOfBirth} data-datepicker="{&quot;format&quot;:&quot;mm / dd / yyyy&quot;,&quot;formatSubmit&quot;:&quot;mm / dd / yyyy&quot;,&quot;max&quot;:&quot;true&quot;,&quot;monthsFull&quot;:[&quot;January&quot;,&quot;February&quot;,&quot;March&quot;,&quot;April&quot;,&quot;May&quot;,&quot;June&quot;,&quot;July&quot;,&quot;August&quot;,&quot;September&quot;,&quot;October&quot;,&quot;November&quot;,&quot;December&quot;],&quot;weekdaysShort&quot;:[&quot;Sun&quot;,&quot;Mon&quot;,&quot;Tues&quot;,&quot;Wed&quot;,&quot;Thurs&quot;,&quot;Fri&quot;,&quot;Sat&quot;]}" data-min-age="{&quot;minAge&quot;:&quot;13&quot;,&quot;errorMessage&quot;:&quot;Based on your calculated age and data collection restrictions for children online we can not accept your inquiry.&quot;}" required aria-haspopup="true" aria-expanded="false" aria-readonly="false" aria-owns="birthday_root" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SignupForm