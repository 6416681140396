// extracted by mini-css-extract-plugin
export var box = "signupForm-module--box--9d0ba";
export var button = "signupForm-module--button--e5558";
export var cafeFormIntro = "signupForm-module--cafe-form-intro--f73b8";
export var emailBox = "signupForm-module--email-box--52792";
export var emailInput = "signupForm-module--email-input--f77dc";
export var firstBox = "signupForm-module--first-box--5604b";
export var form = "signupForm-module--form--399eb";
export var formLabel = "signupForm-module--form-label--71021";
export var highlight = "signupForm-module--highlight--8cc7a";
export var lastBox = "signupForm-module--last-box--018a4";
export var padLeft = "signupForm-module--pad-left--f917b";
export var padRight = "signupForm-module--pad-right--7d9f7";
export var redButton = "signupForm-module--red-button--fa504";
export var redTransparentButton = "signupForm-module--red-transparent-button--6830a";
export var transparentButton = "signupForm-module--transparent-button--70268";