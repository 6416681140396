import React from 'react'
import { graphql } from 'gatsby'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import { RichText } from '../components/Shared/RichText/RichText'
import SignupForm from '../components/SignUpForm/SignupForm'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import SignUpComponent from '../components/SignUp/SignUpComponent'
import { useLocale } from '../fragments/hooks/useLocale'
import Breadcrum from '../components/Breadcrum/Breadcrum'
const SignUp = ({
  location,
  pageContext,
  data: {
      contentfulPageCustom,
      allContentfulPageCustom: {
          nodes
      }
  }
}) => {
  const lang = useUrl()
  const locale = useLocale()
  const{heroImage, description, contactUsOptions, additionalText, slug, seoTitle, seoDescription, cta, breadcrum, pageTitle} = contentfulPageCustom
  
  return (
    <section id = 'PageContact'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}/>
        {breadcrum && <Breadcrum data ={breadcrum} title = {pageTitle}/>}
      <HeroComponent data = {heroImage}/>
      { (locale === 'en-US')  ?
       <SignUpComponent /> : (
       <div className='container container-lg'>
        <div className='signup-desc'>
          <RichText content={description} />
        </div>
        <SignupForm />
        <div className='signup-additional-desc'>
          <RichText content={additionalText} />
        </div>
        <div className='sign-up-button'>
          <button className='red-button'>
            {cta.title}
          </button>
        </div>
      </div> 
      )
    }

    </section>
  )
}

export const PagePageContactQuery = graphql`
    query pagePageContact($id:String!, $contentful_id: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
              seoDescription
            }
            node_locale
            breadcrum {
              title
              slug
            }
            pageTitle
            description{
              raw
            }
            slug
            heroImage {
              title
              heroImage {
                gatsbyImageData
                title
              }
              heroImageMobile {
                gatsbyImageData
                title
              }
            }
            additionalText {
              raw
            }
            cta{
              title
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id},  slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
    }
`
export default SignUp