import React, {useState, useEffect} from 'react'
import * as styles from './signUpComponent.module.scss'
import Spinner from '../Spinner/Spinner'
const SignUpComponent = () => {

    const [loading, setLoading] = useState(false)

    useEffect(() =>{
        const container = document.getElementById('signupForm')
        const signUp = document.createElement('script')
        signUp.src = '//r1.dotdigital-pages.com/resources/sharing/embed.js?sharing=lp-embed&domain=r1.dotdigital-pages.com&id=7HZN-28W%2Fsign-up-form%22'
        signUp.async = true
        signUp.id = 'dot-digital-tazo'
        container?.appendChild(signUp)
        return () => {container?.removeChild(signUp)}
      })
    useEffect(() =>{
        const timer = setTimeout(() => {
          if (document.getElementById('7HZN-28W') === null) {
            window.location.reload()
          }
          return () => clearTimeout(timer)
        }, 4000)
    }, [])

    useEffect(() =>{
      const interval = setInterval(() =>{
        if (document.getElementById('7HZN-28W') === null) {
          setLoading(true)
          if(!document.getElementById("para")){
            const para = document.createElement("p");
            para.innerHTML = "Loading ...";
            para.id = 'para'
            document.getElementById("form-container")?.appendChild(para);
          }
        }else{
          setLoading(false)
        }
      }, 1000)
      return () => clearInterval(interval)
    }, [])
  return (
    <div id = 'form-container'className={styles.formContainer}>
      {loading ? <Spinner /> : ''}
      <div id = 'signupForm' className={`container  ${styles.signup}`}>
        
      </div>
    </div>
  )
}

export default SignUpComponent